/* The purpose of this file is to create classes that match the class names we've used from bulma, which should make for an easier transition to tailwind */

@import "bulma/breadcrumbs.scss";
@import "bulma/typo.scss";
@import "bulma/table.scss";

.footer {
    @apply bg-gray-100 px-4 py-8 mt-10;
}

.hero {
    @apply bg-c-primary-bg text-white flex flex-col justify-between items-stretch;
}

.hero.is-primary {
    @apply text-white bg-c-primary-bg;
}

.navbar-menu {
    @apply -mr-3 flex-grow flex-shrink-0 items-stretch flex shadow-none;
}

.hero.is-primary .navbar-menu {
    @apply bg-c-primary-bg;
}

.hero-head,
.hero-foot {
    @apply flex-grow-0 flex-shrink-0;
}

.navbar {
    @apply min-h-0 relative z-30;
}

.container {
    @apply flex-grow mx-auto relative w-auto;
}

.navbar .container {
    @apply items-stretch flex w-full;
}

.hero .navbar {
    @apply bg-none bg-transparent;
}

.navbar-brand {
    @apply -ml-3 items-stretch flex flex-shrink-0 min-h-0;
}

.navbar a.navbar-item:hover {
    @apply bg-black bg-opacity-20;
}

a.navbar-item:hover {
    @apply no-underline;
}

a.navbar-item {
    @apply no-underline;
}

.navbar-item {
    @apply items-center flex flex-shrink-0 flex-grow-0 px-3 py-2 relative text-current;
}

.navbar-end {
    @apply justify-end ml-auto items-stretch flex;
}

.hero.is-primary a.navbar-item {
    @apply text-current;
}

.hero.is-small .hero-body {
    @apply p-6 flex-shrink-0 flex-grow;
}

.tabs {
    @apply items-stretch flex text-base justify-between overflow-hidden overflow-x-auto whitespace-nowrap select-none;
}

.tabs:not(:last-child) {
    @apply mb-6;
}

.tabs a {
    @apply items-center flex justify-center -mb-0 py-2 px-4 align-top text-opacity-90;
}

.tabs .tab-thin a {
    @apply p-0;
}

.tabs.is-boxed a {
    @apply border border-transparent;
}

.tabs li.is-active a {
    @apply rounded text-opacity-100 bg-opacity-80 border-current text-c-link-hover;
}

.tabs.is-boxed a:hover {
    @apply bg-gray-200 border-gray-100 shadow-inner;
    border-bottom-color: #ddd;
}

.tabs ul {
    @apply items-center flex flex-grow flex-shrink-0 content-start border-b border-gray-300 justify-start;
}

.tabs li {
    @apply block m-0 p-0;
}

.tabs.is-boxed li.is-active a {
    @apply bg-white border-gray-200;
    border-bottom-color: transparent;
}

.columns {
    @apply -mx-3 -mt-3 flex;
}

.columns:last-child {
    @apply -mb-3;
}

.column {
    @apply block flex-grow flex-shrink p-3;
}

.card {
    @apply bg-white rounded text-gray-500 max-w-full overflow-hidden relative shadow-lg;
}

.box {
    @apply bg-white rounded-md shadow-md text-gray-700 block p-5 border-gray-200 border;
}

.box:not(:last-child) {
    @apply mb-6;
}

.panel-block {
    @apply items-center text-gray-800 flex justify-start py-2 px-3;
}

.panel-block:not(:last-child) {
    @apply border-b border-gray-200 border-solid;
}

.notification {
    @apply bg-gray-100 relative p-4;
}

.notification.is-link {
    @apply bg-c-link text-white;
}

.notification.is-link.is-light {
    @apply bg-c-success-pale-bg text-c-darkm-link-text;
}

.notification.is-warning {
    @apply bg-c-warning text-black text-opacity-80;
}

.notification.is-danger.is-light {
    @apply bg-c-darkm-danger-bg text-c-darkm-danger-text;
}

.notification.is-danger {
    @apply bg-c-danger-bg text-white;
}

.section {
    @apply py-12 px-6 block;
}

.field:not(:last-child) {
    @apply mb-3;
}

.select {
    @apply inline-block max-w-full relative align-top;
}

.select:not(.is-multiple) {
    @apply h-10;
}

.select:not(.is-multiple):not(.is-loading)::after {
    @apply border-transparent right-0 z-0;
}

.select:not(.is-multiple):not(.is-loading):hover::after {
    @apply border-transparent;
}

.select select {
    @apply cursor-pointer block text-base text-black text-opacity-70 max-w-full;
    @apply outline-none bg-white rounded border-gray-200 appearance-none;
    @apply h-10 px-3 py-2 relative align-top;
}

.select select:hover {
    @apply border-gray-400;
}

.select select:not([multiple]) {
    @apply pr-10;
}

.label {
    @apply text-black text-opacity-70 block text-base font-bold;
}

.label:not(:last-child) {
    @apply mb-2;
}

.input,
.textarea {
    @apply shadow-inner max-w-full w-full text-gray-800 rounded border-gray-200;
}

.textarea[rows] {
    height: initial;
}

.textarea {
    @apply block min-w-full p-3 resize-y;
}

.input:hover,
.textarea:hover {
    @apply border-gray-400;
}

.input.disabled,
.textarea.disabled,
.select select.disabled,
.input:disabled,
.textarea:disabled,
.select select:disabled {
    @apply text-gray-700 bg-gray-200 border-0 shadow-none;
}

.tag {
    @apply text-xs inline-block py-1 px-2 rounded bg-c-category-tag-bg;
    margin-right: .5em;
}
