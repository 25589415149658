.title {
    @apply font-medium mb-4;
}

.is-sized-1 {
    @apply text-6xl;
}

.is-sized-2 {
    @apply text-5xl;
}

.is-sized-3 {
    @apply text-4xl;
}

.is-sized-4 {
    @apply text-3xl;
}

.is-sized-5 {
    @apply text-2xl;
}

.is-sized-6 {
    @apply text-xl;
}
