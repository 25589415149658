
@layer base {
    body {
        @apply text-c-primary-text;
    }

    a {
        @apply text-c-link;
    }

    a:hover {
        @apply underline text-c-link-hover;
    }

    a.button:hover {
        @apply no-underline;
    }

    .pre-title-hash::before {
        content: "#";
    }

    .max-h-33pct {
        max-height: 33vh;
    }
}

.text-bigger {
    font-size: 120%;
}

.text-biggerer {
    font-size: 141%;
}

.max-w-3\/4 {
    max-width: 75%;
}

.word-wrap-break-word {
    word-wrap: break-word;
}

.word-wrap-normal {
    word-wrap: normal;
}

.word-wrap-anywhere {
    word-wrap: anywhere;
}
