@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./base.scss";
@import "bulma-replace.scss";

.notif-base {
  @apply fixed text-c-primary-text bg-white mt-2 rounded p-4 shadow-2xl;
  transform: translateX(calc(-100% + 3.5rem));
  max-width: 33.333vw;
  min-width: 20rem;
  width: max-content;
}

.notif-container {
  @apply absolute mt-10 p-0;
}

.notif-inner {
  @apply border-b overflow-y-auto;
  max-height: 50vh;
}

.notif-li {
  @apply px-4 py-2;
}

.notif-title {
  @apply text-lg font-semibold;
}

.notif-view-all {
  @apply text-center;
}

.seen {
  @apply bg-gray-100;
}

.notif-p {
  @apply text-xs text-gray-600;
}
