@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "base.scss";
@import "bulma-replace.scss";
@import "notifications.scss";
@import "trees-list.scss";
@import "data-tabs.scss";
@import "markdown.scss";
@import "components/button.scss";
@import "components/items-pipe-between.scss";
@import "monokai.scss";

.btn {
    @apply px-4 py-2 bg-c-success-darker text-white rounded;
}

@layer base {
    body {
        @apply text-c-primary-text;
        word-wrap: anywhere;
    }

    a {
        @apply text-c-link underline;
    }

    a.button, a.navbar-item {
        @apply no-underline;
    }

    a:hover {
        @apply underline text-c-link-hover;
    }

    a.button:hover {
        @apply no-underline;
    }

    *:not(pre) > code {
        @apply px-0.5 bg-gray-100 border rounded border-gray-400;
    }

    strong {
        color: var(--color-c-bold);
    }

    em {
        color: var(--color-c-italics);
    }

    ::selection, ::-moz-selection {
        color: var(--color-c-text-highlighted);
        background: var(--color-c-text-highlighted-bg);
    }
}

[x-convert-markdown] {
    /* if markdown isn't converted for some reason, this will keep it readable */
    @apply whitespace-pre;
}

svg.octicon {
    height: 1em;
    width: 1em;
    position: relative;
}

.tooltip {
    /* transition via opacity */
    @apply opacity-0 invisible;
    @apply transition-all duration-200 ease-linear transform;
    /* move down height:100% (of parent, I think) */
    @apply top-full;
    /* general tooltip stylings */
    @apply absolute px-2 py-1 rounded border border-gray-200 bg-gray-600 shadow-md;
    @apply text-sm font-light text-gray-100 whitespace-nowrap;
}

.has-tooltip:hover .tooltip {
    @apply visible opacity-100 z-50 select-text;
}

.has-tooltip {
    @apply relative inline-flex justify-center;
}

.grid>a {
    @apply col-span-1 px-4 py-2 border-gray-200 rounded border mr-auto whitespace-nowrap;
}

.grid>a:hover {
    @apply border-gray-600;
}

.grid>br {
    display: none;
}

.left { float: left; }
.right { float: right; }

.pagination {
    @apply py-4 w-full flex flex-row items-center justify-center align-middle gap-1;

    > * {
        @apply px-2 select-none;
    }

    > a {
        @apply border hover:border-gray-400 border-transparent transition py-1 rounded;
    }

    > .gap {
        @apply px-1;
    }

    > .current {
        @apply py-1 rounded bg-c-primary-bg text-white;
    }

    > .disabled {
        @apply text-gray-400 select-none;
    }
}
