.breadcrumb {
    @apply text-base whitespace-nowrap;
}

.breadcrumb ol,
.breadcrumb ul {
    @apply flex items-start flex-wrap justify-start list-none m-0 p-0;
}

.breadcrumb li {
    @apply items-center flex m-0 p-0;
}

.breadcrumb a,
.breadcrumb>ul>li>* {
    @apply items-center flex justify-center px-3 py-0;
}

.breadcrumb a:hover {
    @apply text-black text-opacity-70;
}

.breadcrumb li:first-child a {
    @apply pl-1;
}

.breadcrumb li+li::before {
    @apply text-gray-400;
    content: "\0002f";
}

.breadcrumb li.is-active a {
    @apply text-gray-600 cursor-default pointer-events-auto;
}

.breadcrumb:not(:last-child) {
    @apply mb-0;
}
