/*
   * Draw trees in HTML
   * https://two-wrongs.com/draw-a-tree-structure-with-only-css.html
   */

.clt,
.clt .clt-ul,
.clt .clt-li {
    position: relative;
}

.clt .clt-ul {
    list-style: none;
    padding-left: 20px;
}

.clt .clt-li::before,
.clt .clt-li::after {
    content: "";
    position: absolute;
    left: -12px;
}

.clt .clt-li::before {
    border-top: 1px solid #000;
    top: 9px;
    width: 8px;
    height: 0;
}

.clt .clt-li::after {
    border-left: 1px solid #000;
    width: 0px;
    top: 2px;
    @apply h-full;
}

.clt .clt-ul>.clt-li:last-child::after {
    height: 8px;
}

.content .clt .clt-ul {
    list-style-type: none;
    margin-top: 0;
    margin-left: 0;
}

.content .clt-li+.clt-li {
    margin-top: 0;
}

.content .clt .clt-ul .clt-li+.clt-li {
    margin-top: 0;
}

.clt.clt-comments .clt-li+.cli-li {
    @apply mb-4;
}

/* end trees */
