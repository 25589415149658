/* some classes we want to make sure are available:
    float-left float-right
    */

.markdown h1 {
    @apply text-markdown-h1 mb-4 font-light;
}

.markdown h2 {
    @apply text-markdown-h2 mb-4 font-light;
}

.markdown h3 {
    @apply text-markdown-h3 mb-4 font-normal;
}

.markdown h4 {
    @apply text-markdown-h4 mb-4 font-normal;
}

.markdown h5 {
    @apply text-markdown-h5 mb-4 font-medium;
}

.markdown h6 {
    @apply text-markdown-h6 mb-4 font-semibold;
}

.markdown ul {
    @apply list-inside list-disc mb-4;
}

.markdown ol {
    @apply list-inside list-decimal mb-4;
}

.markdown table {
    @apply border mb-4;
}

.markdown div.highlight {
    @apply p-4 mb-2 rounded;
}

.markdown div.highlight pre.highlight {
    @apply overflow-x-auto;
}

.markdown pre {
    @apply mb-4;
}

.markdown hr {
    @apply mb-4;
}

.markdown p {
    @apply mb-4;
}

.markdown :last-child {
    @apply mb-0;
}

.markdown li p {
    @apply inline-block;
}

.markdown table th {
    @apply border font-semibold p-2 bg-gray-800 bg-opacity-10 border-gray-800;
}

.markdown table td {
    @apply border p-2 border-gray-300;
}

.markdown table tr:nth-child(even) {
    @apply bg-gray-600 bg-opacity-10;
}

.markdown blockquote {
    @apply border-l-4 border-l-c-primary pl-4 py-4 my-4 bg-gray-700 bg-opacity-10;
}

.markdown blockquote blockquote {
    @apply py-2 my-2 bg-gray-700 bg-opacity-10;
}

.markdown blockquote p {
    @apply mb-0;
}

.markdown blockquote p:not(:last-child) {
    @apply my-2;
}
