
.button {
    /* whitespace-nowrap */
    @apply rounded border border-gray-300 text-gray-700 justify-center py-2 px-4 text-center shadow bg-white cursor-pointer;
}

.button:hover {
    @apply text-gray-800 border-gray-300 shadow-lg;
}

.button-sm {
    @apply py-1 px-2 text-xs;
}

.button.is-clear {
    @apply bg-opacity-60 bg-white;
}

.button.is-info,
.button.is-success,
.button.is-link {
    @apply bg-c-success border-transparent text-white;
}

.button.is-info:hover,
.button.is-success:hover,
.button.is-link:hover {
    @apply bg-c-success-darker text-white;
}

.button.is-warning {
    @apply bg-c-warning border-transparent text-opacity-70 text-black;
}

.button.is-warning:hover {
    @apply bg-c-warning-darker text-black;
}
