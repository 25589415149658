
/* custom, hopefully more flexible impl of breadcrumbs-like thing */

ol.items-pipe-between {
    @apply list-none;
}

.items-pipe-between > li {
    @apply items-center flex m-0 p-0;
}

.items-pipe-between > li > * {
    @apply flex items-center px-1 py-0;
}

.items-pipe-between > *:not(:first-child)::before {
    @apply text-gray-600;
    content: "\0007C";
}
